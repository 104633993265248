import axios from './network';
import qs from "qs"
import encrypt from "../utils/pubRAS"

export default {
  getUsers(pno,psize){
    return axios.get("/users", {
      params: {
        pno, psize
      },
    })
  },
  addUser(form){
    return axios.post(
      "/users/adduser",
      qs.stringify({...form, pwd: encrypt(form.pwd),repwd:encrypt(form.repwd) },{ indices: false })
    )
  },
  valiName(uname){
    return axios.get("/users/valiname",{
      params:{
        uname
      }
    })
  },
  login(form){
    return axios.post(
      "/users/login",
      qs.stringify({...form , pwd:encrypt(form.pwd) })
    )
  }
}